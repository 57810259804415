import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import Home from './Home';
import Projects from './Projects';


const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  home: Home,
  projects: Projects,

};

export default reducers;
