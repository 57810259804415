import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import { ContentView } from '../../index';
import useStyles from '../../../shared/jss/common/common.style';
import { AppState } from '../../../redux/store';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { AuthUser } from 'types/models/AuthUser';
import LogRocket from 'logrocket';

const useStyle = makeStyles(() => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    background: `url(/assets/images/auth-background.jpg) no-repeat center center`,
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

interface CremaLayoutProps { }

const CremaLayout: React.FC<CremaLayoutProps> = () => {
  useStyles();
  const { navStyle } = useContext<AppContextPropsType>(AppContext);
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser);
  const AppLayout = Layouts[navStyle];

  React.useEffect(() => {
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    // const pendo = require("pendo");
    if (authUser) {

      LogRocket && LogRocket.identify('authUser.email', {
        // name: authUser.firstName + ' ' + authUser.lastName,
        email: authUser.email || '',
        id: authUser.uid
      });

      (window as any).pendo?.initialize({
        visitor: {
          id: authUser.uid,   // Required if user is logged in, default creates anonymous ID
          email: authUser.email,       // Recommended if using Pendo Feedback, or NPS Email
          name: authUser.user?.firstName + ' ' + authUser.user?.lastName,
          org: authUser?.user.organization,
          createdOn: authUser.user.createdOn,
          permissions: authUser.user.permissions //ADMIN, MODEL_ADMIN, EXPERIMENT-AR
          // Recommended if using Pendo Feedback
          // role:         // Optional
          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          org: authUser?.user.organization,
          // id: '' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      });
    }
  }, [authUser]);

  const classes = useStyle();
  return (
    <>
      {authUser ? (
        <AppLayout />
      ) : (
        <Box className={classes.appAuth}>
          <ContentView />
        </Box>
      )}
    </>
  );
};

export default React.memo(CremaLayout);
