import { UseCase } from 'shared/constants/AppConst';
import { LabelObj, ProjectObj, TaskObj } from '../apps/ProjectBoard';
import { Vector2 } from 'three';

import { Model } from '../Model';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';
import { convertFirestoreDates } from '@crema/utility/Utils';
// import { store } from 'App';
export interface SpaceCreateObj {
  sid: string,
  name: string,
  primaryUseCase?: UseCase,
  secondaryUseCase?: UseCase[],
  primaryUseCaseOther?: string,
  secondaryUseCaseOther?: string,
  isSample?: boolean;
}

export interface SpaceCopyObj {
  masterSpaceId: string,
  sid: string,
  name: string,
  primaryUseCase?: UseCase,
  // secondaryUseCase?: UseCase[],
  // primaryUseCaseOther?: string,
  // secondaryUseCaseOther?: string,
  isCleanCopy: boolean;
}
export class SpaceData extends Model {

  sid: string;
  did: string;
  name: string;
  imageSrc?: string;
  homeSweepId?: any;
  settings?: any;
  spaceMembership: SpaceMembership;
  variables?: any[];
  primaryUseCase?: UseCase;
  secondaryUseCase?: UseCase[];
  primaryUseCaseOther?: string;
  secondaryUseCaseOther?: string;
  spaceProjects: ProjectObj[];
  currentSpaceProject: ProjectObj;
  isDeleted: boolean;
  tagsImported?: boolean;
  poses: SpacePose[];
  masterSpaceId?: string;
  isSample?: boolean;

  public static spaceConverter = {
    toFirestore(space: SpaceData) {
      return space;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
    ): SpaceData {

      let data = convertFirestoreDates(snapshot.data(), ['createdOn', 'lastUpdatedOn'], 'Space')
      const space = {

        ...data,
        createdOn: snapshot.data().createdOn?.toDate(),
        lastUpdatedOn: snapshot.data().lastUpdatedOn?.toDate(),

      } as SpaceData;
      // space.createdOn = space.createdOn.toDate();
      // space.lastUpdatedOn = space.lastUpdatedOn.toDate();
      return space;
    }
  };
  // id: string;
  // sid: string;
  // name?: string;
  // createdOn: Date;
  // lastUpdatedTimestamp?: Date;
  // userRole: string;
  // owners: string[];
  // editors?: string[];
  // viewers?: string[];
}

export interface SpacePose {
  id: string;
  label: string;
  data: SweepData;
}

export class SpaceNode extends Model {
  annotationType: string;
  name: string;
  position: string;
  scale: string;
  quaternion: string;

  userData?: any;

  public static nodeConverter = {
    toFirestore(spaceNode: SpaceNode) {
      return spaceNode;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
    ): SpaceNode {

      let data = convertFirestoreDates(snapshot.data(), ['createdOn', 'lastUpdatedOn'], 'Node')
      const spaceNode = {

        ...data,
        id: data.id || snapshot.id,
        createdOn: snapshot.data().createdOn?.toDate(),
        lastUpdatedBy: snapshot.data().lastUpdatedBy || snapshot.data().createdBy

      } as SpaceNode;
      return spaceNode;
    }
  };
}

export interface SpacePermissions {
  spaceId: string;
  spaceSid: string;
  userId: string;
  role: SpaceRole;
  createdOn: Date;
  createdBy: string;
  name?: string;
}

export interface UserSpaceMap {
  spaceId: string;
  data: SpacePermissions;
}

export interface PeopleObj {
  userId: string;
  name: string;
  role: SpaceRole;
  email: string;
  inviteStatus?: InviteStatus;
}

export enum SpaceRole {
  VIEWER = "VIEWER",
  EDITOR = "EDITOR",
  OWNER = "OWNER"
}

export enum InviteStatus {
  INVITED = "INVITED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  UNSEEN = "UNSEEN"
}

export enum ANNOTATION_TYPE {
  INFO = "info",
  QUIZ = "quiz",
  TASK = "task",
}

export enum HomeRoutes {
  SPACE = "space",
  PROJECT = "project",
  LAYERS = "layers",
  TASKS = "tasks",
}

export enum TaskType {
  TASK = "task",
  TASK_TAG = "task_tag",
}
export enum TAG_UPLOAD_TYPE {
  ATTACHMENT = "attachment",
  LINK = "link",
}

export class SpaceMembership extends Model {
  userId: string;
  spaceId: string;
  role: SpaceRole;
}

export class SpaceInvite extends Model {
  userId: string;
  spaceId: string;
  role: SpaceRole;
  inviteStatus: InviteStatus;
}

export interface DeleteConfSnackBar {
  open: boolean;
  message: string;
  type: string;
}
export interface ShowcaseTag {
  id: string;
  data: any;
  createdOn?: Date;
  createdBy?: string;
  lastUpdatedOn?: Date;
  lastUpdatedBy?: string;
  annotationType?: string;
  quizDetails?: IQuizDetails;
  taskTag?: TaskObj,
  tagHtml?: string;
  attachmentsUrls?: any;
  showTextToSpeech?: boolean;
  projectId?: string;
  uploadType?: string;
  labelIds?: number[];
}

export interface SweepData {
  // alignmentType: SweepAlignment
  // floor: number
  // neighbors: string[]
  // placementType: SweepPlacement
  // position: Vector3
  rotation?: Vector3
  sid: string
  // uuid: string
  pose?: Pose
  zoom?: ZoomData
}

export enum SweepAlignment {
  ALIGNED = "aligned",
  UNALIGNED = "unaligned"
}

export enum SweepTransition {
  FADEOUT = "transition.fade",
  FLY = "transition.fly",
  INSTANT = "transition.instant"
}

export enum SweepPlacement {
  AUTO = "auto",
  MANUAL = "manual",
  UNPLACED = "unplaced"
}

export interface Pose {
  mode: any
  position: Vector3
  projection: Float32Array
  rotation: Vector2
  sweep: string
}

export interface ZoomData {
  level: number
}
export interface FilterDataSpaces {
  name: string;
  rating?: number[];
}

export interface HideModal {
  modal: boolean;
}

export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export interface Color {
  b: number;
  g: number;
  r: number;
}
export interface FloorInfo {
  id: string;
  sequence: number;
}

export enum MediaType {
  NONE = 'mattertag.media.none',
  PHOTO = 'mattertag.media.photo',
  RICH = 'mattertag.media.rich',
  VIDEO = 'mattertag.media.video',
}

export interface MattertagDescriptor {
  anchorPosition: Vector3;
  color: Color;
  description: undefined | string;
  floorIndex: undefined | number;
  stemVisible: undefined | false | true;
  stemVector: Vector3;
  media: undefined | MediaType;
  label: undefined | string;
  iconId: undefined | string;
}

export interface MattertagData extends MattertagDescriptor {
  floorInfo: FloorInfo;
  sid: string;
}
export class Lesson extends Model {
  name: string;
  id: string;
  did: string;
  // createdAt: Date;
  creatorId?: string;
  hideVariablesPanel?: boolean;
  defaultVars?: any[];
  isPublic?: boolean;

  create() {

    // make new id
    // set creator, updater meta fieldset
    // save to firestore with converter
    // dispatch as current lesson??? or should actions do it/ callback?
    // dispatch as updated lesson list? or should actions do it/ callback?
  }

  list() {
    // get all for space, if not deleted, with converter
    // dispatch as updated lesson list? or should actions do it/ callback?
  }

  get() {
    // get by id if not deleted, with converter
    // dispatch as current? or should actions do it/ callback?
    // dispatch as updated lesson list? or should actions do it/ callback?
  }

  delete() {
    // mark as deleted ?
    // dispatch as current = null? or should actions do it/ callback?
    // dispatch as updated lesson list? or should actions do it/ callback?
  }

  update() {
    // update with updater meta fields
    // dispatch as current lesson ?? or should actions do it/ callback?
    // dispatch as updated lesson list? or should actions do it/ callback?
  }

  public static lessonConverter = {
    toFirestore(lesson: Lesson) {
      return lesson;
    },

    fromFirestore(
      snapshot: QueryDocumentSnapshot,
    ): Lesson {
      let data = snapshot.data();

      data = convertFirestoreDates(snapshot.data(), ['createdOn', 'lastUpdatedOn'], 'Layer');
      // try {
      //   data.createdOn = ((snapshot.data().createdOn?.toDate() as Date) || new Date());
      // } catch (e) {
      //   console.error(e);
      //   if (typeof data.createdOn == 'string') { //from date string caused by cloning via JSON
      //     data.createdOn = new Date(data.createdOn);
      //   } else if (data.createdOn.seconds) {
      //     data.createdOn = data.createdOn ? new Date(data.createdOn.seconds * 1000) : new Date();
      //   }

      // }
      // if (!data.createdOn?.toDate) {
      //   console.log(`[vars] lesson has toDate ${data.name}`);
      //   data.createdOn = data.createdOn.toDate();

      // } else {
      //   console.log(`[vars] lesson doesn't have toDate ${data.name}`);
      //   data.createdOn = data.createdOn ? new Date(data.createdOn * 1000) : new Date();
      // }

      const lesson = ({
        ...data,
        // createdOn: (snapshot.data().createdOn?.toDate()) as Date,
        // lastUpdatedOn: (snapshot.data().lastUpdatedOn?.toDate() || snapshot.data().lastUpdatedAt?.toDate() ||
        //   snapshot.data().createdOn?.toDate() || snapshot.data().createdAt?.toDate() || new Date()) as Date,
        lastUpdatedBy: data.lastUpdatedBy || data.createdBy
      } as Lesson);
      // space.createdOn = space.createdOn.toDate();
      // space.lastUpdatedOn = space.lastUpdatedOn.toDate();
      return lesson;
    }
  }
}

export interface IScene {
  name: string;
  id: string;
  createdAt: Date;
}

export interface ISceneData {
  name?: string;
  id?: string;
  createdAt?: Date;
  tags?: ShowcaseTag[];
}

export interface IQuizDetails {
  question: string;
  options: { text: string, isCorrect: boolean }[];
}

export interface IShareSpace {
  email: string;
  role: string;
  uid?: string;
  id?: string;
  sid?: string;
  spaceTitle?: string;
}

export interface VarTriggerConfig {
  name: string;
  value: any;
}

// Enum for Activity logs
export enum ACTIONS {
  CLICK = 'click',
  ADD = 'add',
  DELETE = 'delete',
  /** ... */
}

export enum OBJECT_TYPES {
  TAGGROUP = 'taggroup',
  LESSON = 'lesson',
  TOUR = 'tour',
  EXPERIENCE = 'experience',
  STEP = 'step'
  /** ... */
}
export interface IActivityLog {
  id?: string,
  uid?: string,
  action: string,
  object: string,
  objectId: string,
  timestamp: Date
}
export interface HandleTagClickOptions {
  preventNavigation?: boolean;
}

export enum SIDEBAR_INDEX {
  TAGS = 0,
  THREED = 1,
  LAYERS = 2,
  TASKS = 3,
  CATALOG = 4,
  PEOPLE = 5,
  VARIABLES = 6,
  DATA = 7,
  AR = 8,
  BLANK_SCENE = 9,
  NONE = -1
}

export enum CustomDataType {
  CSV = 'csv',
  SALESFORCE = 'salesforce',
  GMAIL = 'gmail',
  JSON = 'json'
}

export interface CustomData {
  id: string;
  name: string;
  customDataType: CustomDataType;
  createdBy: string;
  createdOn: Date;
  lastUpdatedBy?: string;
  lastUpdatedOn?: Date;
}

export enum FieldType {
  Number = 'Number',
  Text = 'Text',
  Date = 'Date',
  Location = 'Location',
  Currency = 'Currency',
  JSON = 'JSON',
  Formula = 'Formula'
}
export interface CustomDataField {
  id: string;
  fieldType: FieldType;
  data: any;
  tagId?: string;
}


export const DateConverter = {
  toFirestore(model: any) {
    return model;
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
  ): any {
    let data = snapshot.data();

    data = convertFirestoreDates(snapshot.data(), ['createdOn', 'lastUpdatedOn'], 'Model');

    return data;
  }
}
