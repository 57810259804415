import Api from '../../@crema/services/ApiConfig';
import { appIntl, BasicProfiler, convertFirestoreDates, toggleInArray } from '../../@crema/utility/Utils';
import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import { fetchError, fetchStart, fetchStartNonBlocking, fetchSuccess, showMessage } from './Common';
import {
  ADD_PROJECT_SECTION,
  ADD_SECTION_TASK,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  DELETE_SECTION,
  DELETE_LIST_TASK,
  EDIT_PROJECT_DETAIL,
  EDIT_SECTION_TASK,
  GET_FEATURE_REQUESTS_DETAIL,
  GET_PROJECTS,
  GET_MEMBER_LIST,
  GET_PROJECT_LABEL_LIST,
  EDIT_PROJECT_LABEL,
  DELETE_PROJECT_LABEL,
  ADD_PROJECT_LABEL,
  ADD_MEMBER_LIST_INVITES,
  UPDATE_TASK,
} from '../../types/actions/Projectboard.actions';
import {
  ProjectObj,
  TaskSectionObj,
  TaskObj,
  LabelObj,
  CollaboratorInvite,
  CollaboratorObj,
  CommentObj,
  TaskCreateObj,
} from '../../types/models/apps/ProjectBoard';
import { GET_SPACE_PROJECTS, SET_SPACE_DATA } from 'types/actions/Home.action';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { store } from 'App';
import { DocumentSnapshot, FieldValue, QuerySnapshot } from '@firebase/firestore-types';
import _, { update } from 'lodash';
import { DateConverter } from 'types/models/home/HomeApp';



export const onGetProjectList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.get('/api/projects/list')
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PROJECTS, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetSpaceProjectList = (did: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    Api.get(`/api/spaces/${did}/spaceProjectList`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SPACE_PROJECTS, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetProjectLabelList = (did: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.get(`/api/projects/${did}/label/list`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PROJECT_LABEL_LIST, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onEditProjectLabel = (projectId: string, label: LabelObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.put('/api/projects/edit/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: EDIT_PROJECT_LABEL, payload: data.data });
          // refreshTagIcons();
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onEditProjectLabelFromSpace = (projectId: string, label: LabelObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const { currentSpace } = getState()?.home || {};
    dispatch(fetchStart());
    Api.put('/api/projects/edit/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_SPACE_DATA, payload: { ...currentSpace, currentSpaceProject: data.data } });
          // dispatch({type: EDIT_PROJECT_LABEL, payload: data.data});
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onAddProjectLabel = (projectId: string, label: LabelObj, onError?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/add/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: ADD_PROJECT_LABEL, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        if (onError) onError();
        dispatch(fetchError(error.response ? error.response.statusText : error.message));
      });
  };
};

export const onAddProjectLabelFromSpace = (projectId: string, label: LabelObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const { currentSpace } = getState()?.home || {};
    dispatch(fetchStart());
    Api.post('/api/projects/add/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SET_SPACE_DATA, payload: { ...currentSpace, currentSpaceProject: data.data } });
          // dispatch({type: ADD_PROJECT_LABEL, payload: data.data});
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onAddProjectCollaborator = (projectId: string, collaborator: CollaboratorObj, onError?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStartNonBlocking('Saving'));
    Api.post('/api/projects/add/collaborator', { projectId, collaborator })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: ADD_MEMBER_LIST_INVITES, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        if (onError) onError();
        dispatch(fetchError(error.response ? error.response.statusText : error.message));
      });
  };
};

export const onDeleteProjectLabel = (projectId: string, taskId: string, labelId: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/delete/label', { projectId, taskId, labelId })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_PROJECT_LABEL, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetMemberList = (did: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.get(`/api/projects/${did}/member/list`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_MEMBER_LIST, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onEditProjectDetail = (project: ProjectObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.put('/api/projects/edit/project', { project })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: EDIT_PROJECT_DETAIL, payload: data.data });
          dispatch(showMessage(messages['projectBoard.projectEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetFeatureRequests = (): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    firestore.collection('FeatureRequests').withConverter(DateConverter)
      .onSnapshot(qs => {
        dispatch(fetchSuccess());

        if (!qs.empty) {
          dispatch({ type: GET_FEATURE_REQUESTS_DETAIL, payload: qs.docs.map(doc => doc.data() as ProjectObj)[0] });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
    // .catch(error => {
    //   dispatch(fetchError(error.message));
    // });
  };
};

export const onGetProjectDetail = (did: string): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.get(`/api/projects/${did}/projectDetail`)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_FEATURE_REQUESTS_DETAIL, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

// export const onGetSpaceProjectDetail = (sid: string) => {
//   const { messages } = appIntl();
//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch(fetchStart());
//     Api.get(`/api/projects/${sid}/spaceProjectDetail`)
//       .then(data => {
//         if (data.status === 200) {
//           dispatch(fetchSuccess());
//           dispatch({ type: GET_FEATURE_REQUESTS_DETAIL, payload: data.data });
//         } else {
//           dispatch(
//             fetchError(messages['message.somethingWentWrong'] as string),
//           );
//         }
//       })
//       .catch(error => {
//         dispatch(fetchError(error.message));
//       });
//   };
// };

export const onAddNewProject = (name: string, spaceId: string, onSuccess?: (project: ProjectObj) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/create', { name, spaceId })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: ADD_NEW_PROJECT, payload: data.data });
          dispatch(showMessage(messages['projectBoard.projectAdded'] as string));
          if (onSuccess) onSuccess(data.data);
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onAddNewSection = (projectId: string, section: TaskSectionObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/add/section', { projectId, section: section })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: ADD_PROJECT_SECTION, payload: data.data });
          dispatch(showMessage(messages['projectBoard.listAdded'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onEditProjectSection = (projectId: string, section: TaskSectionObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.put('/api/projects/edit/section', { projectId, section })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: EDIT_SECTION_TASK, payload: data.data });
          console.log(`[st] [tasks] task section updated`);
          dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onAddNewTask = (task: TaskCreateObj, onSuccess: (task: TaskObj) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    // if (!task.title.trim()) {
    //   dispatch(fetchError('Title can not be empty!'));
    // } else {
    Api.post('/api/projects/add/task', { task })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: ADD_SECTION_TASK, payload: data.data.project });
          // dispatch(showMessage(messages['projectBoard.cardAdded'] as string));
          // let index=data.data.sectionList.findIndex((i:TaskSectionObj)=>(i.id==task.sectionId));
          // let selectedTask=data.data.sectionList[index].taskList[0];
          onSuccess(data.data.task as TaskObj);
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
    // }
  };
};

export const editTaskDetails = (task: TaskObj, onSuccess: (task: TaskObj) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStartNonBlocking('Saving'));
    Api.put('/api/projects/edit/task', { task })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: EDIT_SECTION_TASK, payload: data.data });
          console.log(`[st] [tasks] task edited`);
          // dispatch(showMessage(messages['projectBoard.cardEdited'] as string));
          onSuccess(task);
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteSelectedTask = (
  projectId: string,
  sectionId: number,
  taskId: string,
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/delete/task', { projectId, sectionId, taskId })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_LIST_TASK, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.cardDeleted'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteSelectedProject = (projectId: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/delete', { projectId })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_PROJECT, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteSelectedSection = (projectId: string, sectionId: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/projects/delete/section', { projectId, sectionId })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_SECTION, payload: data.data });
          dispatch(showMessage(messages['projectBoard.listDeleted'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onNullifyProjectDetail = () => {
  return {
    type: GET_FEATURE_REQUESTS_DETAIL,
    payload: null,
  };
};

export function getTaskInProject(id: string): TaskObj | null {
  let p = store.getState().projects?.projectDetail;

  for (let i = 0; i < (p?.sectionList?.length || 0); i++) {
    let s = p?.sectionList[i];
    let taskObj = s?.taskList.find(t => t.id == id);
    if (!!taskObj) {
      return taskObj;
    }
  }
  return null;
}

export const onUpvoteToggle = (projectId: string, taskId: string, userId: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    if (!!taskId) {
      firestore.collection(`Projects`).doc(projectId).collection(`Tasks`).doc(taskId)
        .withConverter(DateConverter)
        .get()
        .then(async (ds: DocumentSnapshot) => {

          let fr = ds.data() as TaskObj;

          fr.upvotedBy = fr?.upvotedBy as string[] || [];
          // fr.upvotedBy = toggleInArray(fr?.upvotedBy as string[] || [], userId);

          if (fr.upvotedBy.includes(userId)) {
            fr.upvotedBy = fr.upvotedBy.filter(id => id !== userId);
          } else {
            fr.upvotedBy.push(userId);
          }
          ds.ref.update('upvotedBy', fr.upvotedBy).then(() => {
            dispatch(fetchSuccess());

            dispatch({ type: UPDATE_TASK, payload: fr }); //FIXME

          })



        })
        .catch(error => {
          console.error(error.message);
          dispatch(fetchError(error.message));
        });
    }
  }
}

export const onAddNewCommentByTaskID = (projectId: string, taskId: string, comment: CommentObj): any => {
  return (dispatch: Dispatch<AppActions>) => {
    if (!!taskId) {
      firestore.doc(`Projects/${projectId}/Tasks/${taskId}`)
        .withConverter(DateConverter)
        .get().then(doc => {

          let t = doc.data() as TaskObj;
          let nc = [...t.comments, JSON.parse(JSON.stringify(comment))];
          doc.ref.update('comments', nc)
            .then(() => {
              dispatch(fetchSuccess());
              // let t = _.cloneDeep(getTaskInProject(taskId));
              !!t && (t.comments = nc) && dispatch({ type: UPDATE_TASK, payload: t }); //FIXME
            })
            .catch(error => {
              console.error(error.message);
              dispatch(fetchError(error.message));
            });
        })
        .catch(error => {
          console.error(error.message);
          dispatch(fetchError(''));
        });
    }
  }
}

export const onAddNewComment = (projectId: string, tagId: string, comment: CommentObj) => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {

    projectId = projectId || store.getState().projects?.projectDetail?.id || '';
    tagId = tagId || store.getState().home?.editShowcaseTagId;

    if (!projectId || !tagId) {
      dispatch(
        fetchError('Something went wrong getting the project and/or task. Please refresh the page and try again'),
      );
    }
    dispatch(fetchStart());

    let taskId = store.getState().home.spaceTags[tagId]?.taskTag?.id || '';
    return dispatch(onAddNewCommentByTaskID(projectId, taskId, comment));

  };
};

export const deleteComment = (projectId: string, taskId: string, commentId: string): any => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {
    firestore.doc(`Projects/${projectId}/Tasks/${taskId}`)
      .withConverter(DateConverter)
      .get().then(doc => {

        let t = doc.data() as TaskObj;
        let nc = t.comments.filter(c => c.id !== commentId)
        doc.ref.update('comments', nc)
          .then(() => {
            dispatch(fetchSuccess());
            // let t = _.cloneDeep(getTaskInProject(taskId));
            !!t && (t.comments =  nc) && dispatch({ type: UPDATE_TASK, payload: t }); //FIXME
          })
          .catch(error => {
            console.error(error.message);
            dispatch(fetchError(error.message));
          });
      })
      .catch(error => {
        console.error(error.message);
        dispatch(fetchError(''));
      });
  }
};
