import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../utility/IntlMessages';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Fonts } from '../../../shared/constants/AppEnums';
import { DialogTitle } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';
import clsx from 'clsx';

type Bg = {
  darkTranslucentBg: boolean
}

type Props = {
  bg: Bg | undefined;
};

// const useStyles = makeStyles<CremaTheme>((theme: CremaTheme) =>
const useStyles = makeStyles((theme) =>
  createStyles({
    dialog:{
      borderRadius: '30px'
    },
    btn: {
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
    },
    btnDark: {
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
      color: "rgb(255,255,255, 50%)"
    },
    contentText: {
      color: grey[900],
    },
    contentTextDark: {
      color: "rgb(255,255,255, 70%)",
    },
    dialogRootDark: {
      '& .MuiPaper-root': {
        backgroundColor: "rgb(0,0,0, 50%)",
        color: "white",

      }
    }

  })
);

export interface ConfirmationDialogProps {
  open: boolean;
  onDeny: (x: boolean) => void;
  onConfirm: () => void;
  title: any;
  dialogTitle: any;
  children?: any;
  yesLabel?: string;
  noLabel?: string;
  isYesButtonCTA?: boolean;
  dark?: boolean;
  cancelNotAllowed?: boolean;
  // dialogRootStyle?: any;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onDeny,
  onConfirm,
  title,
  dialogTitle,
  children,
  yesLabel,
  noLabel,
  isYesButtonCTA = true,
  dark,
  cancelNotAllowed = false,
  // dialogRootStyle
}) => {

  // const classes = useStyles({ bgColor: "00000054" });

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => onDeny(false)}
      // style={dialogRootStyle}
      className={clsx(classes.dialog, dark ? classes.dialogRootDark : '')}
    >

      {/* <Box
          mb={3}
          component='h4'
          fontWeight={Fonts.MEDIUM}
          className='font-bold'
          id='alert-dialog-title'>
          {dialogTitle}
        </Box> */}
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <Box px={{ xs: 5, md: 7 }} pt={{ xs: 4, md: 6 }} pb={{ xs: 2, md: 4 }}>
        <Box>
          <DialogContentText
            className={dark ? classes.contentTextDark : classes.contentText}
            id='alert-dialog-description'>
            {title}
          </DialogContentText>
        </Box>
        {children}
        <Box pt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!cancelNotAllowed && <Button
            className={dark ? classes.btnDark : classes.btn}
            variant='text'
            onClick={() => onDeny(false)}
            color='primary'>
            {noLabel || <IntlMessages id='common.no' />}
          </Button>}
          <Button
            variant={isYesButtonCTA ? 'contained' : 'outlined'}
            className={classes.btn}
            onClick={onConfirm}
            color={isYesButtonCTA ? 'secondary' : 'primary'}
            autoFocus>
            {yesLabel || <IntlMessages id='common.yes' />}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
