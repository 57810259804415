export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  // {
  //   id: 'spaces',
  //   title: 'My Spaces',
  //   messageId: 'sidebar.spaces',
  //   type: 'item',
  //   icon: 'dashboard',
  //   url: '/home/spaces',
  // },
  // {
  //   id: 'sharedSpaces',
  //   title: 'Shared Spaces',
  //   messageId: 'sidebar.sharedSpaces',
  //   type: 'item',
  //   icon: 'share',
  //   url: '/home/shared-spaces',
  // },
  {
    id: 'features',
    title: 'Feature Requests',
    messageId: 'featureRequests',
    type: 'item',
    icon: 'taskAlt',
    url: '/features',
  },
];
export default routesConfig;
