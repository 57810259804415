
export abstract class Model {
  id: string;
  createdOn: Date;
  createdBy: string;
  lastUpdatedOn: Date;
  lastUpdatedBy: string;
}

export function getMetadata(requesterId: string, documentId: string) {
  let serverTimestamp = new Date();

  return {
    id: documentId,
    createdOn: serverTimestamp,
    createdBy: requesterId,
    lastUpdatedBy: requesterId,
    lastUpdatedOn: serverTimestamp
  }
}

export type Result = {
  success: boolean;
  message: string;
  model: Model;
};
