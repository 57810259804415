import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './shared/styles/index.css';
import App from './App';
import '@crema/services';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Changes made here
serviceWorker.register({
    onUpdate: (registration: { waiting: any; }) => {
      const waitingServiceWorker = registration.waiting

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event: { target: { state: string; }; }) => {
          console.log(`[st] [serviceWorker] state changed to ${event.target.state}`);
          if (event.target.state === "activated") {
            if (
              window.confirm(
                "There is a new version of the app ready. Please reload to update."
              )
            ) {
              window.location.reload()
            }
          }
        })
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
      } else {
        console.log(`[st] [serviceWorker] no worker waiting`);
      }
    },
  })
