import { createMuiTheme, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { CremaTheme } from '../../types/AppContextPropsType';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { SpaceData, FilterDataSpaces, SpaceRole } from 'types/models/home/HomeApp';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { store } from 'App';
import _ from 'lodash';

type BreakpointOrNull = Breakpoint | null;

export const isBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const defaultTheme = createMuiTheme();
  return defaultTheme.breakpoints.width(key) > window.innerWidth;
};

export const useDownBreakPointChecker = (
  key: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
) => {
  return useMediaQuery((theme: CremaTheme) => theme.breakpoints.down(key));
};


export const useBreakPointDown = (key: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

export const useWidth = () => {
  const theme: CremaTheme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const createRoutes = (routeConfigs: any[]) => {
  let allRoutes: any[] = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config: any) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return { ...route, auth };
    });
  }

  return [...routes];
};
export const getBreakPointsValue = (valueSet: any, breakpoint: string) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const multiPropsFilterSpaces = (
  spaces: SpaceData[],
  filters: FilterDataSpaces,
  stringKey: string = 'title',
) => {
  const filterKeys = Object.keys(filters);
  return spaces.filter((space) => {
    return filterKeys.every((key) => {
      // @ts-ignore
      if (!filters[key].length) return true;
      // Loops again if space[key] is an array (for material attribute).
      // @ts-ignore
      if (Array.isArray(space[key])) {
        // @ts-ignore
        return space[key].some((keyEle) => filters[key].includes(keyEle));
      }
      // @ts-ignore
      console.log('key', key, filters[key], space[key]);
      if (key === stringKey) {
        // @ts-ignore
        return space[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      // @ts-ignore
      return filters[key].includes(space[key]);
    });
  });
};
export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getCustomDateTime = (
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
): string => {
  if (value === 0) {
    return moment().format(format) as string;
  } else {
    // @ts-ignore
    return moment().add(value, unit).format(format) as string;
  }
};

export const timeFromNow = (date: string) => {
  const timestamp = +moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

// 'intl' service singleton reference
let intl: any;

export function IntlGlobalProvider({ children }: any) {
  intl = useIntl();
  // Keep the 'intl' service reference
  return children;
}

export const appIntl = (): any => {
  return intl;
};
export const checkPermission = (
  routeAuth: any | null | undefined,
  // authUser: any
  role: any | null | undefined,
) => {
  // let role = authUser ? authUser.role : null;
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }
  if (role && Array.isArray(role)) {
    return routeAuth.some((r: any) => role.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !role || role.length === 0;
  }
  if (role && Array.isArray(role) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => role.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(role) >= 0;
};

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function validateUrl(value: string) {
  const re = /^(ftp|http|https):\/\/[^ "]+$/;
  return re.test(String(value).toLowerCase());
}

export function canEditSpace(role?: string) { //TODO param can be removed, kept for backwards compatibility

  // let roleToVerify = role || store.getState().home?.currentSpace?.spaceMembership?.role;
  // if (roleToVerify === SpaceRole.OWNER || roleToVerify === SpaceRole.EDITOR) {
    return true;
  // }
  // return false;
}

export function canViewSpace() {

  if (!store.getState().home?.currentSpace) {
    console.log(`[st] checking space permissions with an empty space`);
    return false;
  }

  let role = store.getState().home?.currentSpace?.spaceMembership?.role;
  if (!!role && [SpaceRole.VIEWER, SpaceRole.EDITOR, SpaceRole.OWNER].includes(role)) {
    return true;
  }
  return false;
}

export function avatarName(name: string = '') {
  return (
    (name.split(' ')[0] || '').charAt(0).toUpperCase() +
    (name.split(' ')[1] || '').charAt(0).toUpperCase()
  );
}

export const objectToMap = (obj: any): Map<any, any> => {
  const keys = Object.keys(obj);
  const map = new Map();
  for (let i = 0; i < keys.length; i++) {
    //inserting new key value pair inside map
    map.set(keys[i], obj[keys[i]]);
  };
  return map;
};

export const mapToObject = (map: Map<any, any>) => {
  return Object.fromEntries(map);
}

export const getNewFirestoreDocId = (collectionName: string): string => firestore.collection(collectionName).doc().id;

export const keyCodeHandlingEvent = async (event: any, onKeyCodeCallback: any) => {
  switch (event.keyCode) {
    case Keys.ESCAPE:
      onKeyCodeCallback(Keys.ESCAPE);
      break;
    case Keys.BACKSPACE:
      onKeyCodeCallback(Keys.BACKSPACE);
      break;
    case Keys.RETURN:
      onKeyCodeCallback(Keys.RETURN);
      break;
    default:
      break;
  }
};

export const getUserAvatar = (authUser: any) => {
  if (authUser && authUser.user && authUser.user.firstName) {
    return authUser.user.firstName.charAt(0).toUpperCase() + authUser.user.lastName.charAt(0).toUpperCase();
  }
  if (authUser && authUser.user && authUser.user.email) {
    return authUser.user.email.charAt(0).toUpperCase();
  }
};

export const mediaFileType = (type: string) => {

  if (['jpeg', 'png', 'jpg', 'gif'].indexOf(type) > -1) {
    return `image/${type}`;
  } else if (['mp4'].indexOf(type) > -1) {
    return `video/${type}`;
  } else if (['pdf'].indexOf(type) > -1) {
    return `application/${type}`;
  } else {
    return ``;
  }

};
export const isObject = (obj: any) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export function showExperimentAlert(message: string) {
  alert(message);
}

export function sleep(milliseconds: number) {

  console.log(`[st] sleeping `);
  //blocking
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds) {
      break;
    }
  }

  //non-blocking
  // const date = Date.now();
  // let currentDate = null;
  // do {
  //   currentDate = Date.now();
  // } while (currentDate - date < milliseconds);
  console.log(`[st] waking `);
}

export function getUrlHashParams() {
  var result: any = {};
  var hashParam = window.location.hash.substring(1);
  var params = hashParam.split("&");

  for (var i = 0; i < params.length; i += 1) {

    var paramSet = params[i].split("=");
    if (typeof (paramSet[1]) !== "undefined") {
      result[paramSet[0]] = decodeURIComponent(paramSet[1]);
    } else {
      result[paramSet[0]] = "";
    }
  };
  return result;
}

interface Lap {
  label?: string;
  ts: number;
  diff: number;
}
export class BasicProfiler {

  name?: string = '';
  t: Array<Lap> = [];

  constructor(name?: string) {

    this.name = name;
    this.t.push({ label: 'Init', ts: new Date().valueOf(), diff: 0 });

  }

  lap(label?: string) {
    let ts = new Date().valueOf();
    let diff = ts - this.t[this.t.length - 1].ts;
    this.t.push({ label, ts: ts, diff: diff });

    console.log(`%c[st] bp: ${label}: ${Math.round(diff / 1000)} seconds`, 'color: lightgreen');
  }

  //  log() {
  // console.log(`[st] BasicProfiler ${this.name}: ${
  //     (this.t.map((e, i) => (i > 0) && e.label + ": " + (this.t[i].ts - this.t[i - 1].ts)))
  // }`);
  // }
}

export function stringify(x: any): string {
  return JSON.stringify(x, JSONCircularReplacer());
}

export const JSONCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: object | null) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};


export const getInQueryResultWithChunks = async ({ collectionName, fieldName, inList, withConverter }: { collectionName: string, fieldName: string, inList: string[], withConverter?: any }) => {
  let inSet = Array.from(new Set(inList));
  let result: any[] = [];
  await Promise.all(
    _.chunk(inSet, 10).map(inListChunk => {

      let q = firestore.collection(collectionName).where(fieldName, 'in', inListChunk);
      withConverter && (q = q.withConverter(withConverter));

      return q.get()
        .then((qs) => {
          let resultChunk: any[] = qs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          result.push(...resultChunk);
        })
      // .catch(console.error);
    }));
  return result;
}

export function convertFirestoreDates(data: any, props: string[], type: string) {

  props.forEach(prop => {
    try {
      // !data[prop] && console.error(`[error] [date] ${prop} not found for ${type} ${store.getState().home.currentSpace?.name} - ${store.getState().home.currentSpace?.id} - ${data.id} `)

      data[prop] = (data[prop]?.toDate() as Date) || new Date();

    } catch (e) {
      // console.error(e);

      if (typeof data[prop] == 'string') { //from date string caused by cloning via JSON
        data[prop] = new Date(data[prop]);
      } else if (data[prop].milliseconds) {
        data[prop] = data[prop] ? new Date(data[prop].milliseconds) : new Date();
      } else if (data[prop].seconds) {
        data[prop] = data[prop] ? new Date(data[prop].seconds * 1000) : new Date();
      }
    }
  })

  return data;
}

export enum Keys {
  ESCAPE = 27,
  ZERO = 48,
  ONE = 49,
  TWO = 50,
  THREE = 51,
  FOUR = 52,
  FIVE = 53,
  SIX = 54,
  SEVEN = 55,
  EIGHT = 56,
  NINE = 57,
  LEFTARROW = 37,
  UPARROW = 38,
  RIGHTARROW = 39,
  DOWNARROW = 40,
  TAB = 9,
  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,
  SPACE = 32,
  RETURN = 13,
  DELETE = 46,
  BACKSPACE = 8,
  SEMICOLON = 186,
  PLUSEQUALS = 187,
  COMMA = 188,
  PERIOD = 190,
  FORWARD_SLASH = 191,
  DASHUNDERSCORE = 189,
  BACKSLASH = 220,
  CLOSEBRACKET = 221,
  SINGLE_QUOTE = 222,
  SHIFT = 16,
  ALT = 18,
  CONTROL = 17,
  NUMPAD_LEFT = 100,
  NUMPAD_RIGHT = 102,
  NUMPAD_UP = 104,
  NUMPAD_DOWN = 98,

}
export function GetHexWithAlpha(colorHex: string, alpha: number): string {
  let alphaString = Math.ceil(alpha * 255).toString(16);
  alphaString = alphaString.length < 2 ? "0" + alphaString : alphaString;
  return colorHex + alphaString;
}

export function SimpleClone(x: any): any {
  return JSON.parse(JSON.stringify(x));
}

export function isUserAdmin(): boolean {
  return !!store.getState().auth.authUser?.user?.permissions?.includes('ADMIN');
}

/** Doesn't modify original array */
export function toggleInArray<T>(arr: T[] | undefined | null, el: T): T[]{
  return arr?.includes(el) ? (arr || []).filter(i => i !== el) : [...(arr || []), el];
}