import { Common } from '../../types/models/Common';
import * as CommonAction from '../../types/actions/Common.action';
import { ConfirmationDialogProps } from '@crema/core/ConfirmationDialog';

const INIT_STATE: Common = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  loadingInfo: '',
  showDictaphone: false,
  status: '', // non-blocking
  alert: {
    open: false,
    onDeny: () => { },
    onConfirm: () => { },
    title: '',
    dialogTitle: '',
    children: [],
    yesLabel: 'Yes',
    noLabel: 'No',
    isYesButtonCTA: true,
    dark: false,
    cancelNotAllowed: false
  }
};

const CommonReducer = (
  state = INIT_STATE,
  action: CommonAction.CommonActionTypes,
): Common => {
  switch (action.type) {
    case CommonAction.ROUTE_CHANGE: {

      return {
        ...state,
        error: '',
        message: ''
      };
    }

    case CommonAction.FETCH_START: {
      return {
        ...state,
        error: '',
        message: '',
        loading: true,
        loadingInfo: action.payload || ''
      };
    }
    case CommonAction.FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        loading: false,
        updatingContent: false,
      };
    }
    case CommonAction.SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.message,
        loading: false,
        updatingContent: false,
      };
    }
    case CommonAction.FETCH_ERROR: {
      setTimeout(() => {
        // dispatch({type: FETCH_ERROR,""})
      }, 6000);

      return {
        ...state,
        loading: false,
        error: action.error,
        message: '',
        updatingContent: false,
      };
    }
    case CommonAction.HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        updatingContent: false,
      };
    }
    case CommonAction.TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case CommonAction.SHOW_DICTAPHONE: {
      return {
        ...state,
        showDictaphone: action.payload
      };
    }
    case CommonAction.SHOW_STATUS: {
      return {
        ...state,
        status: action.payload
      };
    }
    case CommonAction.FETCH_START_NON_BLOCKING: {
      return {
        ...state,
        loadingInfo: action.payload,
        loading: true
      };
    }

    case CommonAction.SHOW_ALERT: {
      return {
        ...state,
        alert: action.payload
      };
    }

    case CommonAction.HIDE_ALERT: {
      return {
        ...state,
        alert: {...state.alert, open: false, title: ''}
      };
    }
    default:
      return state;
  }
};
export default CommonReducer;
