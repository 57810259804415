import { AuthUser } from '../../types/models/AuthUser';
import { AuthType } from './AppEnums';
import mpc from 'shared/styles/MatterportColors.json';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const initialUrl = '/'; // this url will open after login
// export const initialUrl = '/ecommerce/products'; // this url will open after login

export const channels = {
  email: 'email',
  notification: 'notification',
};

export const templates = {
  task: 'task',
  shareSpace: 'shareSpace',
  signup: 'signup',
};

export interface UseCase {
  id: number;
  name: string;
  layerTermSingular: string;
  layerTermPlural: string;
}

export const defaultLayerNameSingular = "Experience";
export const defaultLayerNamePlural = "Experiences"

export const UseCases: UseCase[] = [
  { id: 0, name: "Training", layerTermSingular: "Lesson", layerTermPlural: "Lessons" },
  { id: 1, name: "Hotel/ Airbnb", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 2, name: "Home Owner/ Home Manual", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 3, name: "Layout Planning", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 4, name: "Interior Design", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 5, name: "Retail", layerTermSingular: "Tour", layerTermPlural: "Tours" },
  { id: 6, name: "Facilities Management", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 7, name: "Construction", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 8, name: "Office Manual", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  // { id: 9, name: "Monitoring & Remote Control", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 100, name: "Other", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
];

export const taskStatusColors = ['#F87E7D', '#FFCD7A', '#FEFF00', '#7ED085', '#5FB3F9', '#D190DA', '#D0AF8F', '#BDBDBD', '#FFCCD2', '#FFE1AF', '#FEFF85', '#C6E7C8', '#B9DDFC', '#EBC7EF', '#EEDCCA', '#E5E5E5', '#FFEBEE', '#FFF3DF', '#FFFEE6', '#E7F5E9', '#E2F2FE', '#F4E4F5', '#F2E8DE', '#FFFFFF'];
// export const labelList = [
//   {id: '0', name: 'Scheduled'}, {id: 1, name: 'In Progress'}, {id: 2, name: 'Complete'}, {id: 3, name: 'Needs Attention'}];

export const getUniqueColor = (colorsToExclude: string[]) => taskStatusColors.find(c => !colorsToExclude.includes(c)) || taskStatusColors[0];

export const TagColorToMatterportColorObj = (tagColorObj: any): { r: number, g: number, b: number } => {
  let c = tagColorObj;

  let r = (c.r * 256).toFixed(6);
  let g = (c.g * 256).toFixed(6);
  let b = (c.b * 256).toFixed(6);

  return rgbToMatterportColorObj(r, g, b);

}

export const defaultTaskLabelColor = "#e8e5e4";
export const defaultTaskCollaboratorColor = "#e8e5e4";

export function RGBToHex(r: any, g: any, b: any) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1)
    r = "0" + r;
  if (g.length == 1)
    g = "0" + g;
  if (b.length == 1)
    b = "0" + b;

  return "#" + r + g + b;
}

export function hexToRGB(hex: string): number[] | undefined {
  if(hex.startsWith('#')){
    hex = hex.substring(1)
  }
  if (!hex || hex.length != 6) {
    console.error("Only six-digit hex colors are allowed.");
    return undefined;
  }

  var aRgbHex = hex.match(/.{1,2}/g);

  if (aRgbHex) {
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16)
    ];
    return aRgb;
  }
  return undefined;
}

export function hexToMatterportColorObj(hex: string) {
  if(!hex){
    return getDefaultMatterportColorObj();

  }
  let tc = hexToRGB(hex);
  if (!!tc && tc.length == 3) {

    let r = tc[0].toString();
    let g = tc[1].toString();
    let b = tc[2].toString();

    return rgbToMatterportColorObj(r, g, b);

  } else {
    return getDefaultMatterportColorObj();
  }

}

export function rgbToMatterportColorObj(r: string, g: string, b: string) {

  let colorEntry = mpc.colors.find((c: any) => c.r256 === r && c.g256 === g && c.b256 === b);
  // !colorEntry && console.log(`[st] ${JSON.stringify( c)}`);

  return !colorEntry ? getDefaultMatterportColorObj() : { r: colorEntry.r, g: colorEntry.g, b: colorEntry.b };

}

export function getDefaultMatterportColorObj() {
  return { r: 1, g: 0, b: 0 };
}