import { Model } from "./Model";

export enum RELEASE_LEVEL { patch = 'patch', minor = 'minor', major = 'major' };
export class Release extends Model {
  level: RELEASE_LEVEL;
  title: string;
  items: ReleaseItem[];
  isDraft: boolean;
  mustReload?: boolean;

  constructor() {
    super();
    this.level = RELEASE_LEVEL.patch;
    this.title = '';
    this.items = [];
    this.isDraft = true;
    this.mustReload = false;
  }
}

export class ReleaseCreateObj {


}

export class ReleaseItem {
  title: string;
  details?: string;

  constructor() {
    this.title = '';
  }
}

export class UserRelease {
  id: string; //release ID
  seen: boolean;
  userId: string;
}