import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
import {Redirect} from 'react-router-dom';

export const appsConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: [
          '/',
        ],
        component: React.lazy(() => import('./ProjectBoard')),
      },
      // {
      //   path: '/apps/contact',
      //   component: React.lazy(() => import('./Contact')),
      // },
    ],
  },
];
