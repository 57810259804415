import { getUrlHashParams } from '@crema/utility/Utils';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { showMessage, updateUserThirdPartyToken } from 'redux/actions';
import { history } from 'redux/store';
import { SET_CURRENT_SIDEBAR_INDEX } from 'types/actions/Home.action';
import { SketchfabToken, SketchfabTokenData } from 'types/models/dataAccess/User';
import { SIDEBAR_INDEX } from 'types/models/home/HomeApp';
import { authRole, initialUrl } from '../../shared/constants/AppConst';
import { store } from 'App';
import _ from 'lodash';

// function RerouteToHome() {

//   // render(
//   return (history.push(initialUrl), React.lazy(() => import('./Dashboard')))
//   // )

// }

export const homeConfig = [
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       path: '/home/spaces',
  //       component: React.lazy(() => import('../apps/ProjectBoard')),
  //     }
  //   ],
  // },
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       path: '/home/space/:did/project/:pDid?/:menu?/:mid?',
  //       component: React.lazy(() => import('./SpaceDetail')),
  //     },
  //   ],
  // },

];
