import { firestore } from "@crema/services/auth/firebase/firebase";
import { DocumentData, DocumentReference, GeoPoint, Timestamp } from "@firebase/firestore-types";
import shortUUID from "short-uuid";

export abstract class IModel{
    id: string;
    createdOn: Date;
    createdBy: string;
    lastUpdatedOn: Date;
    lastUpdatedBy: string;
}


export abstract class IModelV2{
    id: string = shortUUID.generate();
    createdOn: number = Date.now();
    createdBy: string;
    lastUpdatedOn: number = Date.now();
    lastUpdatedBy: string;

    constructor(uid: string){
        this.createdBy = uid;
        this.lastUpdatedBy = uid;
    }
}

export class ResultFailure<T> {
    constructor(
        errorCode?: number,
        message?: string,
        ){
    }
}

export class ResultSuccess<T> {
    constructor(
        object: T,
        message?: string,
        ){
    }
}

export class Result<T>{

    result?: T | T[];
    errorCode?: number;
    message?: string;

    error(errorCode?: number, message?: string){
        this.errorCode = errorCode;
        this.message = message;
        return this;
    }
    success(result: T | T[] | undefined){
        this.result = result;
        return this;
    }
}

export function isTimestamp(x: unknown): x is Timestamp {
    return typeof x === 'object' && x !== null && 'toDate' in x;
  }

  export function isGeoPoint(x: unknown): x is GeoPoint {
    return typeof x === 'object' && x !== null && x.constructor.name === 'GeoPoint';
  }

  export function isDocumentReference(x: unknown): x is DocumentReference {
    return typeof x === 'object' && x !== null && x.constructor.name === 'DocumentReference';
  }

  export function isObject(x: unknown): x is Record<string, unknown> {
    return typeof x === 'object';
  }

  export function transformFirestoreTypes(docData: DocumentData){
  Object.keys(docData).forEach(key => {
    const val = docData[key];


    if (!docData[key]) return;
    if (isTimestamp(val)) {
        docData[key] = val.toDate();
    } else if (isGeoPoint(val)) {
      const { latitude, longitude } = val;
      docData[key] = { latitude, longitude };
    } else if (isDocumentReference(val)) {
      const { id, path } = val;
      docData[key] = { id, path };
    } else if (isObject(val)) {
        // console.log(`[st] ${key} = ${val}`);
        transformFirestoreTypes(val);
    }
  });
  return docData;
}

export abstract class Repo<T extends IModel> { //extends IModel<T>{
    abstract getDbPath(id?: string): string;

    // abstract getDocRef(id?: string): DocumentReference;

    mapDbToObject(docData: DocumentData, id: string): T
    {
        //TODO what if docData is undefined
        // console.log(`[st] model is ${Object.keys(docData)} `);
            let tt = transformFirestoreTypes(docData) as T;

            // console.log(`[st] model is ${tt}`);

            // Object.keys(tt).forEach(key => {
            //     const val = docData[key];
            //     console.log(`[st] ${key} = ${val}`);
            // });


            // console.log(`[st] model id ${id} ${JSON.stringify(tt)}`);
            return tt;


    //      let model: T = Object.assign({}, dbObject) as T;
    //      model.id = id;
    //      console.log(`[st] typeof model ${typeof model}`);

    //      return model;
     }

    async get(id: string): Promise<T>{
        let doc = await firestore.doc(this.getDbPath(id)).get();


        if(doc.data() == undefined){
            throw new Error("User not found");
        } else {
            // console.log(`[st] model id ${doc.id} ${JSON.stringify(doc.data())}`);
            // console.log(`[st] get ${Object.keys(doc.data() as DocumentData)}`);
            return this.mapDbToObject(doc.data() as DocumentData, doc.id);
        }

    }

    async create(object: T) : Promise<T> {
        let ref;

        if(object.id){
            ref = firestore.doc(this.getDbPath(object.id));
            // console.log(`[st] creating with id ${object.id}`);
            await ref.set(object);
            return object;
        }
        else {
            // console.log(`[st] creating without id ${object.id}`);
            // ref = firestore.collection(this.getDbPath(object.id)).doc();
            let docRef = await firestore.collection(this.getDbPath()).add(object);
            // let docRef = await ref.set(object);
            let docSnap = await docRef.get();
            // console.log(`[st] model created ${docSnap.id}`);

            let model = docSnap.data() as T;
            model.id = docSnap.id;
            return model;

        }
    }

    async delete(id: string) : Promise<boolean> {


        await firestore.doc(this.getDbPath(id)).delete();
        return true;


        // .then(() => true)
        // .catch(error =>  {console.log(error);
        // return false;})

    }

    // async createWithTxn(objectArray: T[]) : Promise<T> {

    // }

}
// export interface ModelMethods<T> { //extends IModel<T>{
//     dbPath: string;
//     create(object: T) : ResultSuccess<T>;
// }
// export abstract class Model<T> implements IModel<T>{
//     id: string;
//     createdOn: Date;
//     createdBy: string;
//     lastUpdatedOn: Date;
//     lastUpdatedBy: string;
//     dbPath: string;

//     abstract create() : ResultSuccess<T>;
//     abstract update(object: T): ResultSuccess<T> | void;
//     abstract get(id: string): T;
//     abstract delete(idOrObject: string | T): T;
// }


// export abstract class Model implements IModel {

//     id: string;
//     createdOn: Date;
//     createdBy: string;
//     lastUpdatedOn: Date;
//     lastUpdatedBy: string;

//     create(object: IModel){
//         object.createdOn = new Date();
//         object.lastUpdatedOn = new Date();
//         // object.lastUpdatedBy = //TODO
//     }

//     update(object: IModel){
//         object.lastUpdatedOn = new Date();
//         // object.lastUpdatedBy = //TODO
//     }

//     get(id: string): IModel {
//         throw new Error("Method not implemented.");
//     }

//     delete(idOrObject: string | IModel): IModel {
//         throw new Error("Method not implemented.");
//     }

// }

